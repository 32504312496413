export const checkAuthCookies = (directPath: string) => {
  const value = document.cookie;
  const dayCookie = value
    .split('; ')
    .find((cookie) => cookie.split('=')[0] === '24-hour-cookie');
  const cookies = value.split('; ').filter((cookie) => {
    const cookieKey = cookie.split('=')[0];
    return (
      cookieKey === 'idToken'
      || cookieKey === 'accessToken'
      || cookieKey === 'FA_UT'
    );
  });
  let subdomain = '';
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'localDev':
      subdomain = 'dev.';
      break;
    case 'dev':
    case 'staging':
    case 'demo':
      subdomain = `${process.env.REACT_APP_ENVIRONMENT}.`;
      break;
    // prod
    default:
      break;
  }
  if (dayCookie && cookies.length === 3) {
    const now = new Date();
    const expireTime = new Date(now.getTime() + 15 * 60000);
    document.cookie = `${cookies[0]}; ${
      process.env.REACT_APP_ENVIRONMENT !== 'localDev'
      && `domain=.${subdomain}app.fintelconnect.com;`
    } expires=${new Date(expireTime).toUTCString()}; path=/;`;
    document.cookie = `${cookies[1]}; ${
      process.env.REACT_APP_ENVIRONMENT !== 'localDev'
      && `domain=.${subdomain}app.fintelconnect.com;`
    } expires=${new Date(expireTime).toUTCString()}; path=/;`;
    document.cookie = `${cookies[2]}; ${
      process.env.REACT_APP_ENVIRONMENT !== 'localDev'
      && `domain=.${subdomain}app.fintelconnect.com;`
    } expires=${new Date(expireTime).toUTCString()}; path=/;`;
  } else {
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');

    document.cookie = `${dayCookie}; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.${subdomain}app.fintelconnect.com; path=/;`;
    const url = `https://${subdomain}${directPath}`;
    window.location.replace(url);
  }
  return null;
};
