import Auth0 from './auth0';

// TODO: refresh token

if (process.env.REACT_APP_ENVIRONMENT) {
  document.cookie = process.env.REACT_APP_24_HOUR!;
  document.cookie = process.env.REACT_APP_FA_UT!;

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'publisher':
      document.cookie = process.env.REACT_APP_PUBLISHER_ATOKEN!;
      document.cookie = process.env.REACT_APP_PUBLISHER_IDTOKEN!;
      break;
    case 'merchant':
      document.cookie = process.env.REACT_APP_MERCHANT_ATOKEN!;
      document.cookie = process.env.REACT_APP_MERCHANT_IDTOKEN!;
      break;
    default:
      document.cookie = process.env.REACT_APP_ADMIN_ATOKEN!;
      document.cookie = process.env.REACT_APP_ADMIN_IDTOKEN!;
      break;
  }
}

export function clearIdToken() {
  localStorage.removeItem('id_token');
}

export function clearAccessToken() {
  localStorage.removeItem('access_token');
}

export const reset = () => {
  clearIdToken();
  clearAccessToken();
};

export const login = (username: string, password: string) => new Promise((resolve, reject): void => {
  Auth0.obj.login(
    {
      realm: 'Username-Password-Authentication',
      username,
      password,
    },
    (err, result) => {
      if (err) {
        reject();
      }
      resolve(result);
    },
  );
});

export const refreshToken = () => new Promise((resolve, reject) => {
  Auth0.obj.checkSession(
    {},
    (err: any, session: { accessToken: string; idToken: string }) => {
      if (err) {
        reject(err);
      }
      reset();
      localStorage.setItem('access_token', session.accessToken);
      localStorage.setItem('id_token', session.idToken);
      resolve(session);
    },
  );
});

export const removeSession = async () => {
  try {
    await refreshToken();
    return true;
  } catch (err) {
    clearIdToken();
    clearAccessToken();
    return false;
  }
};

// Archived code
export const getToken = () => {
  let token = localStorage.getItem('access_token');

  // retrieve token from url
  const accessCookie = document.cookie.split('; ').find((cookie) => {
    const cookieKey = cookie.split('=')[0];
    return cookieKey === 'accessToken';
  });
  const accessToken = accessCookie?.split('=')[1];
  if (typeof accessToken === 'string') {
    localStorage.setItem('access_token', accessToken);
    token = accessToken;
  }
  return token;
};

export const setTokenAndRefresh = () => {
  // refresh page
  setTimeout(() => {
    window.location.href = '/inbox';
  }, 200);
};
